import { useEffect } from 'react';
import classNames from 'classnames';

import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import { PureLink } from '@jsmdg/yoshi';
import { type NavigationItem } from '../../../shared/types';
import { SPECIAL_HIGHLIGHTED_ITEM } from '../../helper/specialNavigationItemIds';
import {
    availableTrackingActions,
    trackingCategories,
    trackNavigationClick,
} from '../../helper/trackNavigation';
import { useActivateListItemWithDelay } from '../../hooks/useActivateListItemWithDelay';
import { useHoverTracking } from '../../hooks/useHoverTracking';
import { DesktopNavigationGroup } from './DesktopNavigationGroup/DesktopNavigationGroup';
import styles from './DesktopNavigation.module.scss';

function isCategoryLink(category: NavigationItem): boolean {
    return Boolean(category.uri && !category?.children?.length);
}

type DesktopNavigationProps = {
    readonly toggleBackdrop: (showBackdrop: boolean) => void;
};

const DesktopNavigation = ({ toggleBackdrop }: DesktopNavigationProps): JSX.Element => {
    const currentNavigationDepth = 0;
    const maxNavigationDepth = 2;
    const { setIsFromTouchEvent, topNavigationItems, trackDesktopNavigationHoverIn } =
        useHoverTracking(currentNavigationDepth, maxNavigationDepth);

    const { activeItemIndex, resetActiveItemIndexWithDelay, setActiveItemIndexWithDelay } =
        useActivateListItemWithDelay();

    useEffect(() => {
        toggleBackdrop(
            Boolean(activeItemIndex > -1 && topNavigationItems[activeItemIndex]?.children?.length),
        );
    }, [activeItemIndex, toggleBackdrop, topNavigationItems]);

    return (
        <div className="d-none d-md-flex">
            <ul className={classNames(styles.desktopNav, 'p-0 d-flex w-100')}>
                {topNavigationItems.map((item: NavigationItem, index: number) => {
                    const navigationItemHasChildren = item?.children?.some(
                        child => child?.children && child.children.length > 0,
                    );

                    const trackingData = {
                        eventName: GA4EventName.OpenNavigationItem,
                        feature_category: GA4FeatureCategory.TopNavigation,
                        click_element: item.title as string,
                        click_text: item.title,
                        navigationlevel: 0,
                    };

                    return (
                        <li
                            role="presentation"
                            className={classNames(styles.navCategory, 'py-1x', {
                                [styles.open]: activeItemIndex === index,
                                [styles.highlighted]: item.id === SPECIAL_HIGHLIGHTED_ITEM,
                            })}
                            key={item.id}
                            onTouchStart={() => setIsFromTouchEvent(true)}
                            onMouseLeave={() => {
                                resetActiveItemIndexWithDelay();
                            }}
                            onMouseEnter={() => {
                                setActiveItemIndexWithDelay(index);
                                trackDesktopNavigationHoverIn(
                                    item,
                                    item.title as string,
                                    trackingData,
                                );
                            }}
                            onClick={e =>
                                trackNavigationClick({
                                    e,
                                    category: trackingCategories.TOP_NAVI,
                                    action: availableTrackingActions.CLICK,
                                    eventData: {
                                        ...trackingData,
                                        eventName: GA4EventName.ClickNavigationItem,
                                        click_url: window.location.origin + item.uri,
                                    },
                                })
                            }
                        >
                            {isCategoryLink(item) ? (
                                // TODO: check for adjustment in navigation backend JSON to reinstate Magazin
                                <PureLink
                                    href={item.uri}
                                    className={classNames(
                                        'font-complementary',
                                        styles.categoryLink,
                                    )}
                                    // remove spreaded props when rendition of judgement on AT may be removed
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...(item.id === 'urteilsveroeffentlichung' && {
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                    })}
                                    internal
                                >
                                    {item.title}
                                </PureLink>
                            ) : (
                                <>
                                    <span
                                        className={classNames(
                                            'font-complementary position-relative',
                                            styles.categoryTitle,
                                        )}
                                    >
                                        {item.title}
                                    </span>
                                    {item.children && (
                                        <div
                                            className={classNames(
                                                'mt-1x position-absolute',
                                                styles.searchFlyoutWrapper,
                                                {
                                                    [styles.flyoutWrapperWithMultiColumn]:
                                                        navigationItemHasChildren,
                                                },
                                            )}
                                        >
                                            <DesktopNavigationGroup
                                                className={
                                                    navigationItemHasChildren ? 'w-50' : 'w-100'
                                                }
                                                group={item}
                                                depth={currentNavigationDepth}
                                                maxDepth={maxNavigationDepth}
                                                parentTrackingLabel={item.title as string}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export { DesktopNavigation };
